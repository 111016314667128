export const white = "#FFFFFF";
export const grayUltraLight = "#FCFBFA";
export const grayExtraLight = "#F5F6F7";
export const grayLight = "#CED4DE";
export const gray = "#7D899C";
export const grayDark = "#161632";
export const grayExtraDark = "#1D2330";
export const dark = "#13161F";
export const blueLight = "#e9f2fa";
export const blue = "#ff2837";
export const skyBlue = "#1FB6FF";
export const negative = "#EB4D4B";
