/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui";
import { useCurrentDoc } from "docz";

import * as styles from "./styles";

import { Logo } from "../Logo";
import Logout from "./Logout"


export const Header = () => {
  const { edit = true, ...doc } = useCurrentDoc();
  const [colorMode, setColorMode] = useColorMode();

  const toggleColorMode = () => {
    setColorMode(colorMode === "light" ? "dark" : "light");
  };

  return (
    <div sx={styles.wrapper}>
      <div sx={styles.innerContainer}>
        <Logo />
        <Logout />
      </div>
    </div>
  );
};
